<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI营销软文" />
                        <!-- <ai_select selectTitle="软文类型" :select_list="style_list" @getSelectVal="get_style" /> -->
                        <div class="form_ct flex">
                            <p class="upLoad_text">产品名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.product_name" placeholder="请简易描述您表达的软文主题内容"></el-input>
                            </div>
                        </div>
                        <!-- <div class="form_ct flex">
                            <p class="upLoad_text">关键词</p>
                            <el-input v-model="form.keyword" placeholder="如:有趣的建筑"></el-input>
                        </div> -->
                        <ai_button_file_data @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title'
import ai_select from '@/components/ai_select'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    name: '',
    created() { },
    mounted() { this.getUserInfo() },
    components: {
        wordNav,
        ai_title,
        ai_select,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                type: '',
                product_style: '',
                product_name: '',
            },
            style_list: [
                {
                    id: '新闻事件型',
                    name: '新闻事件型'
                },
                {
                    id: '争议型',
                    name: '争议型'
                },
                {
                    id: '悬念型',
                    name: '悬念型'
                },
                {
                    id: '分享型',
                    name: '分享型'
                },
                {
                    id: '情感型',
                    name: '情感型'
                },
                {
                    id: '故事型',
                    name: '故事型'
                },
                {
                    id: '健康型',
                    name: '健康型'
                },
                {
                    id: '促销型',
                    name: '促销型'
                },
                {
                    id: '揭秘型',
                    name: '揭秘型'
                },
            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            selectList: ['美食', '自然', '专题', '历史', '生活', '宣传', '其他'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_style(val) {
            this.form.product_style = val
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentData.output_content).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80,
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80,
                    });
                }
            );
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/create_article', {
                // product_style: this.form.product_style,
                product_name: this.form.product_name,
                mobile: this.$user_info.mobile,
            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai.scss'
</style>